import React from "react";
import {Container, H3, Phone, TextListContainer} from "../styles/Styles";
import styled from "styled-components";
import {device} from "../utilities/Breakpoint";


type textType = {
    value: {
        title: string,
        is_bold: boolean,
        hidden: boolean,
        isPhone: boolean
    }
}

interface TextWithMaskProps {
    backgroundStyle: string,
    textLeft: textType[],
    textRight: textType[]

}

const Col = styled.div`
    flex: 1 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width:768px) {
        flex-basis: 100%;
    }
    
    align-items: center;
    align-content: center;
`

type RoundBackgroundType = {
    backgroundUrl: string;
}

const RoundBackground = styled.div<RoundBackgroundType>`
  width: 100%;
  @media screen and ${device.laptop} {
    position: absolute;
    transform: translate(0, -70px);
    height: 400px;
    background-image: ${(props) => 'url(' + props.backgroundUrl + ')'};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 400px;
    width: auto;
    min-width: 400px;
  }
`
type BoldType = {
    bold: boolean
}
const BlackH3 = styled(H3)<BoldType>`
  color: white;
  font-weight: ${(props) => props.bold ? 600 : 400};
  
  @media screen and ${device.laptop} {
      color: black;
      text-align: center;
      transform: translate(0, 70px);
      width: 70%;
      padding: 0 15% 0 15%;
  }

`

const WhiteH3 = styled(H3)<BoldType>`
  font-weight: ${(props) => props.bold ? 600 : 400};
  align-self: flex-start;
`
const PadddingTop = styled.div`
    padding-top: 1.6rem;
`


export const TextWithMask: React.FC<TextWithMaskProps> = ({textLeft, textRight, backgroundStyle}) => {

    const setRightSide = (textRight: textType[]) => {
        return textRight.map(item => !item.value.hidden && phoneOrText(item))
    }

    const phoneOrText = (item: textType) => item.value.isPhone ?
        <BlackH3 bold={false} key={"phone"}><Phone hasHover={true} href={"tel: " + item.value.title}
                                                   key={item.value.title}>{item.value.title}</Phone></BlackH3> :
        <BlackH3 bold={item.value.is_bold}
                 key={item.value.title}>{item.value.title}</BlackH3>

    const leftSide = (textLeft: textType[]) => {
        return textLeft.map(item => <WhiteH3 bold={item.value.is_bold}
                                             key={item.value.title}>{item.value.title}</WhiteH3>)
    }


    return (
        <Container backgroundStyle={backgroundStyle}>
            <TextListContainer>
                <Col>
                    <PadddingTop>
                        {leftSide(textLeft)}
                    </PadddingTop>
                </Col>
                <Col>
                    <RoundBackground backgroundUrl={"https://admin.tierarztpraxis-enge.ch/vet-cockpit/storage/uploads/2022/11/10/636d5fb4aa6c6kreis.png"}>
                        {setRightSide(textRight)}

                    </RoundBackground>
                </Col>
            </TextListContainer>
        </Container>
    )
}
