import React, {CSSProperties} from "react";
import {API} from "../config/config";
import {H2, Hero, HeroOverlay, ImageOverlay, ImageOverlayContainer} from "../styles/Styles";
import {
    BrowserView,
    MobileView,
} from "react-device-detect";

interface HeroImageProps {
    backgroundColor?: string;
    image: {
        path: string
    };
    imageMobile: {
        path: string
    };
    imageStyle?: string;
    title: string;
    backgroundStyle: string;
}


const defaultImageStyle: CSSProperties = {
    width: "100%",
    height: "100%",
};

export const HeroImage: React.FC<HeroImageProps> = ({backgroundColor, image, imageMobile, imageStyle, title}) => {
    return (
        <HeroOverlay>
            <BrowserView style={defaultImageStyle}>
                <Hero backgroundUrl={API.assetUrl + image.path} style={defaultImageStyle}/>
            </BrowserView>
            <MobileView style={defaultImageStyle}>
                <Hero backgroundUrl={API.assetUrl + imageMobile.path} style={defaultImageStyle}/>
            </MobileView>
            <ImageOverlayContainer>
                <ImageOverlay>
                    <H2>{title}</H2>
                </ImageOverlay>
            </ImageOverlayContainer>
        </HeroOverlay>)
}
