import { HeroImage } from "./HeroImage";
import { TeamList } from "./TeamList";
import { TextList } from "./TextList";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { NavSectionTitle } from "./NavSectionTitle";
import { LinkList } from "./LinkList";
import { TextWithMask } from "./TextWithMask";
import { Overlay } from "./Overlay";

export const componentList: any = {
  Overlay: Overlay,
  HeroImage: HeroImage,
  imageGallery: TeamList,
  textList: TextList,
  linkCollection: LinkList,
  contact: Contact,
  footer: Footer,
  sectionTitle: NavSectionTitle,
  textMasked: TextWithMask,
};
