import React from "react";
import useFetch from "../hooks/useFetch";
import {API} from "../config/config";
import ContentBuilder from "./ContentBuilder";
import filterNavigation from "../hooks/filterNavigation";
import {Header} from "../components/Header";

export interface NavigatorProps {
    // Navigation Elements for top lvl Navigation
    match: {
        url: string
    };
}

const Navigator: React.FC<NavigatorProps> = ({match = {url: ""}}) => {
    let currentSegment;
    if (match.url === '/') {
        currentSegment = 'de'
    } else {
        currentSegment = match.url.replace('/', '')
    }


    const [websiteUris]  = useFetch(`${API.baseUrl}api/singletons/listSingletons?token=${API.key}`, []) as [Array<string>]

    const [spaContent] = useFetch(`${API.baseUrl}api/singletons/get/${currentSegment}?token=${API.key}`, []) as any

    return (
        <>
            <Header
                key={"Navigationkey"}
                navigationItems={filterNavigation({layout: spaContent.layout})}
                websiteUri={websiteUris}
            />
            <ContentBuilder content={spaContent.layout}/>
        </>
    )
}


export default Navigator
