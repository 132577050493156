export const StyleConfiguration: any = {
    "default": {
        backgroundColor: "#ffffff",
        color: "#333333",
        hover: "#333333"
    },

    "primary": {
        backgroundColor: "#839CB8",
        color: "#ffffff",
        hover: "#000000"
    },

    "secondary": {
        backgroundColor: "#8FB09C",
        color: "#ffffff",
        hover: "#000000"
    },

    "tertiary": {
        backgroundColor: "#C2B052",
        color: "#ffffff",
        hover: "#000000"
    },
}
