import React from "react";
import {componentList} from "../components/ComponentList";

export interface ContentBuilderProps {
    content: ComponentItem[];
}

export interface ComponentItem {
    component: string;
    settings: {
        key: string;
        [propName: string]: any;
    }
}


const ContentBuilder: React.FC<ContentBuilderProps> = ({content = []}) => {

    const layout = () => {
        return content.map(item => {
            if (componentList.hasOwnProperty(item.component) && item.settings.key) {
                const Component = componentList[item.component];
                const props = item.settings;
                return <Component {...props} />
            } else {
                console.warn(item.component, " muss hinzugefügt werden")
                return <></>
            }
        })
    }

    return <>{layout()}</>;

}

export default ContentBuilder;
