import React from 'react'
import styled from 'styled-components'
import {device} from "../utilities/Breakpoint";

interface StyledBurgerProps {
    open: boolean
}

const StyledBurger = styled.button<StyledBurgerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 4rem;
  transform: scale(1.5);

   @media ${device.tablet}{  
    display: none
  }
  
  &:focus {
    outline: none;
  }

  div {
    width: 1.5rem;
    height: 0.15rem;
    background: black;
    border-radius: 5px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({open}) => open ? '0' : '1'};
      transform: ${({open}) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

interface MobileNavigationType {
    open: boolean,
    fn: any
}

const MobileNavigation: React.FC<MobileNavigationType> = ({open, fn}) => (
    <StyledBurger open={open} onClick={() => {
        fn(!open);
    }}>
        <div/>
        <div/>
        <div/>
    </StyledBurger>
);


export default MobileNavigation;
