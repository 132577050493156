import styled from "styled-components";
import {StyleConfiguration} from "../utilities/StyleConfig";

export type styleConfig = {
    backgroundStyle: string;
}
type imageConfig = {
    backgroundUrl: string;
}
type hoverConfig = {
    hasHover: boolean;
}
export const font = "Helvetica";
export const H2 = styled.h2`
    font-size: 2.4rem;
    margin: 1rem 0 2rem 0;
    text-transform: uppercase;
    `
export const H3 = styled.h3`
    font-size: 1.8rem;
    margin: 1rem 0;
    `

export const P = styled.p`
    font-size: 1.4rem;
    display: block;
    margin: 1rem;
    `
export const UL = styled.ul`
    list-style-type: none;
    margin: 0 1rem;
    padding: 0;
    `

export const LI = styled.li`
    margin: 10px;
    `

export const SectionTitle = styled.div<any>`
    margin: 2rem 0;
    border-radius: 10px;
    border: 3px solid;
    padding: 1rem 2rem 0 2rem;
`

export const MainContainer = styled.div`
    padding: 0 4rem;
`
export const Container = styled.div<styleConfig>`
    display: flex;
    margin: 0 -4rem;
    padding: 2rem 4rem;
    justify-content: center;
    background-color: ${(props) => StyleConfiguration[props.backgroundStyle].backgroundColor};
    color: ${(props) => StyleConfiguration[props.backgroundStyle].color};
    @media screen and (max-width: 768px) {
        padding: 2rem;
    }
`
export const ContactContainer = styled.div`
    max-width: 1024px;
    display: flex;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
`
export const TextListContainer = styled.div`
    display: flex;
    max-width: 1024px;
    width: 100%;
    padding-bottom: 2rem;
    @media screen and (max-width:768px) {
        flex-wrap: wrap;
    }
`
export const TextListSection = styled.div`
    flex: 1 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width:768px) {
        flex-basis: 100%;
    }
`
export const ContactSection = styled.div`
    flex: 1 0;
    @media screen and (max-width:768px){
        flex-basis: 100%;
    }
`
export const MapContainer = styled.div`
    width: 100%;
    height: 400px;
    margin: 0 auto;
    @media screen and (max-width: 768px){
        max-width: 350px;
    }
`
export const HeroOverlay = styled.div`
    position: relative;
    height: calc(100vh - 80px);
    margin: 8rem -4rem 0 -4rem;
    @media screen and (max-width: 768px){
        height: calc(100vh - 53px);
        margin-top: 0;
    }
    `
export const ImageList = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    width: 100%;
    `
export const ListImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0;
    margin: 0 3rem 3rem 3rem;
`
export const ImageDescription = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    border-top: 1px solid;
    width: 100%;
    padding-top: 1.6rem;
    `
export const ImageOverlayContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    `
export const ImageOverlay = styled.div`
    width: 100%;
    max-width: 1024px;
    margin: 2rem auto;
    padding: 0 4rem;
    color: #ffffff;
    @media screen and (max-width:768px) {
        width: auto;
        margin: 2rem;
    }
`
export const FooterList = styled.ul`
    display: flex;
    list-style-type: none;
`

export const SpacerMd = styled.div`
  padding-top: 1rem;
  width: 100%;
  background-color: green;
`
export const Hero = styled.div<imageConfig>`
    background-image: ${(props) => 'url(' + props.backgroundUrl + ')'};
    background-position: 50% 30%;
    background-repeat: no-repeat;
    @media screen and (max-width: 768px) {
        background-size: 115%;
    }
`
export const LinkItem = styled.div`
    color: inherit;
    text-decoration: none;
    margin-right: 1rem;
    `
export const Link = styled.a`
    color: inherit;
    text-decoration: none;
    display: block;
    transition: all 200ms ease-in;

    :hover {
        color: #839CB8;
    }
    `
export const Phone = styled.a<hoverConfig>`
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    margin-top: 1rem;
    display: block;
    transition: all 200ms ease-in;

    :hover {
        color: ${(props) => props.hasHover ? "#839CB8" : "#ffffff"};
    }
    `
export const Logo = styled.img`
    max-height: 60px;
    height: 60px;
    max-width: 125px;
    width: 125px;
    margin-left: 20px;
    cursor: pointer;
    
    @media screen and (max-width: 768px) {
        max-height: 40px;
        max-width: 85px;
    }
    `
export const ItemWithIcon = styled.div`
    display: flex;
    align-items: center;
    height: 7rem;
`
export const ListIcon = styled.img`
    max-width: 5rem;
    max-height: 5rem;
    margin-right: 2rem;
    `
