import React from "react";
import styled from "styled-components";
import {device} from "../utilities/Breakpoint";
import MobileNavigationBreadcrumb from "./MobileNavigation";
import {Logo} from "../styles/Styles";
import NavigationLink from "./NavigationLink";
import {StyleConfiguration} from "../utilities/StyleConfig";

type headerTypes = {
    backgroundColor: string;
};

type listVerticalProps = {
    listItemColor: string;
};

type listItemProps = {
    listItemHoverColor: string;
};

const BackgroundColor = styled.div<headerTypes>`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  height: 5.3rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(242, 242, 242);
  }
    @media ${device.tablet} {
    height: auto;
  }
`;

const ListVertical = styled.ul<listVerticalProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: ${(props) => props.listItemColor};
  direction: rtl;
  height: 100%;
`;

const ListItemDefault = styled.li`
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 2.5rem 1.5rem;
  
  @media ${device.tablet} {
    padding: 1.5rem;
   }
`

const ListItem = styled(ListItemDefault)<listItemProps>`
  text-decoration: none;
  transition: all 250ms ease-in;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
    color: ${(props) => props.listItemHoverColor};
  }
`;

const NavigationContainer = styled.div`
  position: fixed;
  background-color: white;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  z-index: 140;
  box-shadow: 0px 0px 10px #999999;

  
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8rem;
  }
`;

const UnstyledLink = styled.a`
  color: inherit;
  text-decoration: inherit;
  transform: scale(1);
  font-size: 22px;
  line-height: 2em;
  font-weight: 400;
  text-transform: uppercase;
  
  @media screen and ${device.tablet} {
    font-size: 14px;
  }
`;


const DesktopNavigation = styled.div`
  display: none;
  
 @media ${device.tablet} {
  display: flex;
  align-items: center;
  padding-right: 6rem;
 }
`
const MobileEntriesWithPadding = styled.p<any>`
  position: absolute;
  top: calc(5rem + 0.3rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 1rem 0;
  margin: 0;
  line-height: 1.4em;
  font-size: 14px;
  color: black;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100vw;
  z-index: 150;
  opacity: ${props => (props.open) ? "100%" : "0"};
  margin-top: ${props => (props.open) ? "-0.3rem" : "0"};
  
  transition: all 250ms ease-in-out;
  
  @media ${device.tablet} {  
    display: none;
  }
`

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Spacer = styled.div`
  flex: 1 1;
`


export interface HeaderProps {
    /**
     * Optional BackgroundColor
     */
    backgroundColor?: string;
    /**
     * Optional Mode
     */
    mode?: string;
    /**
     * Navigation Items
     */
    navigationItems: string[];
    /**
     * Color of the List Items
     */
    listItemColor?: string;
    /**
     * Color of the List Items
     */
    listItemHoverColor?: string;
    /**
     * Website uris
     */
    websiteUri: string[];
}

export const Header: React.FC<HeaderProps> = ({
                                                  backgroundColor = "white",
                                                  navigationItems = [],
                                                  listItemColor = "white",
                                                  listItemHoverColor = "#839CB8",
                                                  websiteUri = ["de", "en"]
                                              }) => {


    const buildDesktopNavigation = () => (
        <DesktopNavigation>
            <ListVertical listItemColor={listItemColor}>
                {navigationItems.map((item: string) => (
                    <ListItem listItemHoverColor={listItemHoverColor} key={item}>
                        <UnstyledLink href={"#" + item}>{item}</UnstyledLink>
                    </ListItem>
                ))}
                <ListItemDefault key={"testestsdfasdf"}>
                    {websiteUri.map((item: string, index: number) =>
                        <NavigationLink key={item} styling={Object.keys(StyleConfiguration)[index % 3 + 1]}
                                        label={item}/>)}
                </ListItemDefault>
            </ListVertical>
        </DesktopNavigation>
    )

    const buildMobileDropdown = (open: boolean) => (
        <MobileEntriesWithPadding open={open}>
            {navigationItems.map((item: string) => (
                <UnstyledLink key={"#" + item} href={"#" + item} onClick={() => setOpen(!open)}>{item}</UnstyledLink>
            ))}
            <span>
            {websiteUri.map((item: string, index: number) =>
                <NavigationLink key={item + index} onClickLink={() => setOpen(!open)}
                                styling={Object.keys(StyleConfiguration)[index % 3 + 1]}
                                label={item}/>)}
            </span>
        </MobileEntriesWithPadding>
    )

    const [open, setOpen] = React.useState(false)

    return (
        <BackgroundColor backgroundColor={backgroundColor}>
            <NavigationContainer>
                <SpaceBetween>
                    <Logo onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} alt="Logo"
                          src={"https://admin.tierarztpraxis-enge.ch/vet-cockpit/storage/uploads/2020/10/03/5f789bd61ef23logo.png"}/>
                    <Spacer></Spacer>
                    {buildDesktopNavigation()}
                    <MobileNavigationBreadcrumb open={open} fn={setOpen}/>
                </SpaceBetween>
                {buildMobileDropdown(open)}
            </NavigationContainer>
        </BackgroundColor>
    );
};
