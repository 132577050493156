import React, { useState } from "react";
import styled from "styled-components";
import { API } from "../config/config";


interface OverlayContainerProps {
    active: boolean;
}

interface OverlayProps {
    src: {
        path: string
    };
}

const OverlayContainer = styled.div<OverlayContainerProps>`
    display: ${({active}) => active ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 150;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #80808096;
`

const ImageOverlay = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`
const OverlayImage = styled.img`
    max-width: 80vw;
    max-height: 80vh;
    border-radius: 5px;
`

const TopRight = styled.div`
    font-size: 1.5rem;
    transform: translate(1.5rem, 1.5rem);
    background-color: white;
    padding: 0.75rem 1rem;
    border-radius: 50%;
    align-self: flex-end;
`


export const Overlay: React.FC<OverlayProps> = ({src}) => {
    const [visible, setVisisble] = useState(true)

    return (
        <OverlayContainer active={visible}  onClick={() => setVisisble(false)}>
            <ImageOverlay>
                <TopRight>X</TopRight>
                <OverlayImage src={API.assetUrl + src.path} alt="Information" />
            </ImageOverlay>
        </OverlayContainer>
)
}
