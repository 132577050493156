import React from "react";
import {ContactContainer, ContactSection, Container, H3, MapContainer, P, Phone} from "../styles/Styles";
import GoogleMapReact from 'google-map-react';
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'
import "../styles/map.css"

interface ContactProps {
    backgroundStyle: string,
    content: textType[],
}


type textType = {
    value: {
        title: string,
        content: string,
        content_line2: string,
        content_line3: string,
        is_phone: boolean,
        key: string,
        hidden: boolean
    }
}

type State = {
    lat: number,
    lng: number,
    zoom: number,
}

const defaultZoom = 17;
const practiceLocation: State = {
    lat: 47.354879,
    lng: 8.526680,
    zoom: defaultZoom,
}
// @ts-ignore
const LocationPin = ({ lat, lng, text }) => (
    <div className="pin">
        <Icon icon={locationIcon} className="pin-icon" />
        <p className="pin-text">{text}</p>
    </div>
)
export const Contact: React.FC<ContactProps> = ({backgroundStyle, content}) => {

    const setTextContent = (textLeft: textType[]) => {
        return textLeft.map((item: textType, index: number) => !item.value.hidden &&
            <div key={item.value.title + index}>
                <H3>{item.value.title}</H3>
                <P>{item.value.is_phone ?
                    <Phone hasHover={false} href={"tel: " + item.value.content}>{item.value.content}</Phone> :
                    item.value.content}
                </P>
                {!!item.value.content_line2 ? <P>{item.value.content_line2}</P> : ""}
                {!!item.value.content_line3 ? <P>{item.value.content_line3}</P> : ""}
            </div>
        )
            ;
    }

    const state: State = {
        lat: practiceLocation.lat,
        lng: practiceLocation.lng,
        zoom: practiceLocation.zoom,
    }
    return (
        <Container backgroundStyle={backgroundStyle}>
            <ContactContainer>
                <ContactSection>
                    {setTextContent(content)}
                </ContactSection>
                <ContactSection>
                    <MapContainer>

                        <GoogleMapReact
                            bootstrapURLKeys={{key: "AIzaSyBJtstQa8PkEF-EX1PDDdSYYqCkli8-9go"}}
                            defaultCenter={{lat: state.lat, lng: state.lng}}
                            defaultZoom={state.zoom}
                        >
                            <LocationPin
                                lat={practiceLocation.lat}
                                lng={practiceLocation.lng}
                                text={"Tierarztpraxis Enge"}
                            />
                        </GoogleMapReact>
                    </MapContainer>
                </ContactSection>
            </ContactContainer>
        </Container>
    );
}
