import React from "react";
import {Container, H3, Link, LinkItem, Phone, TextListContainer, TextListSection} from "../styles/Styles";

interface LinkListProps {
    text_link_phone: text_link_url[];
    backgroundStyle: string
}

interface LinkProps {
    _id: string;
    url: string
    text: string;
    phone: string;
}

type text_link_url = {
    value: {
        text: string,
        url: string,
        phone: string
    }
}

export const LinkList: React.FC<LinkListProps> = ({text_link_phone, backgroundStyle}) => {

    let i, j;
    let columns = [];
    let chunk = (text_link_phone.length / 2) + (text_link_phone.length % 2);

    for (i = 0, j = text_link_phone.length; i < j; i += chunk) {
        columns.push(text_link_phone.slice(i, i + chunk));
    }

    return (
        <Container backgroundStyle={backgroundStyle}>
            <TextListContainer>
                {columns.map((columnItem: text_link_url[], Index: number) => {
                    return (<TextListSection
                            key={Index + columnItem[Index].value.text + columnItem[Index].value.text + columnItem[Index].value.phone + columnItem[Index].value.url}>
                            {columnItem.map((item: text_link_url, keyIndex: number) => {
                                    return (
                                        <H3 key={item.value.text + item.value.url + item.value.phone + Index + keyIndex}>
                                            <LinkItem>
                                                <Link href={item.value.url} target="_blank"
                                                      rel="noopener noreferrer">{item.value.text}</Link>
                                                <Phone hasHover={true}
                                                       href={"tel:" + item.value.phone}>{item.value.phone}</Phone>
                                            </LinkItem>
                                        </H3>
                                    )
                                }
                            )
                            }
                        </TextListSection>
                    )
                })}
            </TextListContainer>
        </Container>
    )
}
