import  React, {CSSProperties} from "react";
import {Container, H3, ImageDescription, ImageList, ListImage, P,} from "../styles/Styles";
import {API} from "../config/config";
import useFetch from "../hooks/useFetch";

const defaultImageStyle: CSSProperties =
    {
        maxHeight: 350,
        height: 350,
        width: 250,
        maxWidth: 250,
        objectFit: "cover",
        boxShadow: "0 5px 10px #999",
    };

export interface ListImageProps {
    _id: string;
    img: {
        path: string;
    };
    title: string;
    description: string;
}

interface TeamListProps {
    collectionName: string
    backgroundStyle: string
}

type collectionShape = {
    entries: ListImageProps[]
}

export const TeamList: React.FC<TeamListProps> = ({collectionName = "", backgroundStyle}) => {
    const [content] = useFetch(`${API.baseUrl}api/collections/get/${collectionName}?token=${API.key}`, {entries: []}) as [collectionShape];

    return (
        <Container backgroundStyle={backgroundStyle}>
            <ImageList>
                {content.entries.map((tile: ListImageProps) => (
                    <ListImage key={tile._id}>
                        <img
                            src={API.assetUrl + tile.img.path}
                            alt={tile.title}
                            style={defaultImageStyle}
                        />
                        <ImageDescription>
                            <H3>
                                {tile.title}
                            </H3>
                            <P>
                                {tile.description}
                            </P>
                        </ImageDescription>
                    </ListImage>
                ))}
            </ImageList>
        </Container>
    )
}
