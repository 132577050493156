import React from "react";
import {Container, H3, ItemWithIcon, ListIcon, styleConfig, TextListContainer, TextListSection} from "../styles/Styles";
import useFetch from "../hooks/useFetch";
import {API} from "../config/config";
import styled from "styled-components";
import {StyleConfiguration} from "../utilities/StyleConfig";

type listEntry = {
    value: {
        text: string,
        icon: string,
        link: string,
        key: string
    }
}

interface TextListProps {
    entries: listEntry[]
    backgroundStyle: string
}

export interface ListIconProps {
    _id: string;
    img: {
        path: string;
    };
    name: string;
}

type iconCollection = {
    entries: ListIconProps[]
}

const StyledLink = styled.a<styleConfig>`
  font-size: 1.8rem;
  margin: 1rem 0;
  color: ${(props) => StyleConfiguration[props.backgroundStyle].color};
  
  &:hover {
    color: ${(props) => StyleConfiguration[props.backgroundStyle].hover};
  }
`
const SpacerWrapper = styled.div`
  margin-left: 7rem;
  display: flex;
  height: 7rem; 
  align-items: center;
`


export const TextList: React.FC<TextListProps> = ({entries, backgroundStyle}) => {
    const [content] = useFetch(`${API.baseUrl}api/collections/get/icons?token=${API.key}`, {entries: []}) as [iconCollection];
    let i, j;
    let columns = [];
    let chunk = (entries.length / 2) + (entries.length % 2);

    for (i = 0, j = entries.length; i < j; i += chunk) {
        columns.push(entries.slice(i, i + chunk));
    }

    return (
        <Container backgroundStyle={backgroundStyle}>
            <TextListContainer>
                {columns.map((columnItem: listEntry[], index: number) => {
                    return (<TextListSection key={index.toString() + columnItem[index].value.key}>
                            {columnItem.map((item: listEntry, kIndex: number) => {
                                    const currentIcon = content.entries.find((iconItem: ListIconProps) => {
                                        return iconItem.name === item.value.icon
                                    });
                                    return (!!currentIcon ?
                                            <ItemWithIcon key={index.toString() + item.value.key + kIndex.toString()}>
                                                <ListIcon src={API.assetUrl + currentIcon.img.path} alt={"icon"}/>
                                                <H3 key={item.value.text}>
                                                    {item.value.text}
                                                </H3>
                                            </ItemWithIcon>
                                            :
                                            <SpacerWrapper key={item.value.key + index.toString() + kIndex.toString()}>
                                                <H3>
                                                    {(!!item.value.link ?
                                                            <StyledLink backgroundStyle={backgroundStyle}
                                                                        href={item.value.link}>{item.value.text}</StyledLink>
                                                            :
                                                            <span>{item.value.text}</span>
                                                    )}
                                                </H3>
                                            </SpacerWrapper>
                                    )
                                }
                            )
                            }
                        </TextListSection>
                    )
                })}
            </TextListContainer>
        </Container>
    )
}
