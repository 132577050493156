import React from "react";
import {Container, H2, SectionTitle} from "../styles/Styles";
import styled from "styled-components";

interface NavSectionTitleProps {
    title: string
    backgroundStyle: string;
}

const AnchorLink = styled.a`
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
`

export const NavSectionTitle: React.FC<NavSectionTitleProps> = ({title, backgroundStyle}) => {

    return (
        <Container backgroundStyle={backgroundStyle}>
            <AnchorLink className="anchor" id={title}></AnchorLink>
            <SectionTitle><H2>{title}</H2></SectionTitle>
        </Container>
    )
}


